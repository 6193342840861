import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";

import posthog from "posthog-js";
import { nanoid } from "nanoid";

const getClientId = () => {
  let userId = window.localStorage.getItem("userId");

  if (!userId) {
    userId = nanoid();

    window.localStorage.setItem("userId", userId);
  }

  return userId;
};

if (process.env.NODE_ENV === "production") {
  posthog.init("phc_Yn1dsJedTJquMn5XSDrahqAn0etHoRbUMoUP3y0GumU", {
    api_host: "https://app.posthog.com",
  });

  const email = new URLSearchParams(location.search || "").get("email");

  posthog.identify(getClientId(), {
    email: email,
  });

  Sentry.init({
    dsn: "https://70c370f4840742e68a4135c9615859e3@o1071232.ingest.sentry.io/4505312761937920",

    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    integrations: [new Sentry.Replay()],
  });
}

import Markdown from './examples/Markdown/index'

export const navigation = [

  {
    name: "Markdown",
    path: "/",
    element: <Markdown />,
  },
];

const router = createBrowserRouter([
  ...navigation,

]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />
);
